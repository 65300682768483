import React from 'react'

interface LoaderProps {
  message: string
}

const Loader: React.FunctionComponent<LoaderProps> = (props) => (
  <div className='u-marginTop120 pux-container'>
    <h2 style={{ textAlign: `center` }}>{props.message}</h2>
    <div className='loader'></div>
  </div>
)

export default Loader
